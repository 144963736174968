<template>
  <div class="counter-content">
    <textarea 
      v-model="text" 
      placeholder="여기에 텍스트를 입력하세요"
      class="text-input"
    ></textarea>
    <div class="stats-container">
      <div class="stat-item">
        <span class="stat-label">글자 수</span>
        <span class="stat-value">{{ text.length }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">공백 제외</span>
        <span class="stat-value">{{ charactersExcludingSpaces }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">바이트 수</span>
        <span class="stat-value">{{ byteCount }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">단어 수</span>
        <span class="stat-value">{{ wordCount }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">공백 수</span>
        <span class="stat-value">{{ spaceCount }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">줄 수</span>
        <span class="stat-value">{{ lineCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ''
    }
  },
  computed: {
    charactersExcludingSpaces() {
      return this.text.replace(/\s/g, '').length;
    },
    wordCount() {
      if (this.text.trim() === '') return 0;
      return this.text.trim().split(/\s+/).length;
    },
    spaceCount() {
      const spaces = this.text.match(/\s/g);
      return spaces ? spaces.length : 0;
    },
    lineCount() {
      if (this.text === '') return 0;
      return this.text.split(/\n/).length;
    },
    byteCount() {
      // 바이트 수 계산
      return new Blob([this.text]).size;
    }
  }
}
</script>

<style lang="scss" scoped>
.counter-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.text-input {
  width: 100%;
  min-height: 200px;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.2);
  }

  &::placeholder {
    color: #9e9e9e;
  }
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    background: #f0f2f5;
  }

  .stat-label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }

  .stat-value {
    font-size: 24px;
    font-weight: 600;
    color: #4CAF50;
  }
}

@media (max-width: 600px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .stat-item {
    padding: 10px;

    .stat-value {
      font-size: 20px;
    }
  }
}
</style>

<template>
  <article>
    <h1>30대 소개팅의 모든 것</h1>
    <p class="intro">
      30대. 경제적으로 여유로워진 나이.<br>
      하지만 20대 처럼 마냥 놀 수는 없는 나이.<br>
      <br>
      30대가 되신 걸 축하드립니다!<br>
      <br>
      직장인이 되면 학창 시절 때 와는 달리 이성을 만나는 루트가 달라지요.<br>
      어떤 방법들이 있는지 살펴볼까요?
    </p>

    <h2>1. 동호회</h2>
    <p>
      예로부터 동호회는 남녀 만남의 장소였어요.<br>
      와인 동호회, 등산 동호회, 사진 동호회 등등<br>
      이런 온갖 동호회들이 겉으로는 취미를 가장하지만 실상은 남녀가 만나기 위해 모인다는 걸 이제 우리는 다 알고 있죠.<br>
      <br>
      이렇다보니 아무 모임이나 가면 흑심을 품은 남정네들만 만나게 되곤해요.<br>
      특히 몇년생들 모여라, 어디 지역 사람들 모여라 같은 동호회가 그런 동호회들이에요.<br>
      <a href="https://cafe.naver.com">네이버 카페</a>나, <a href="http://www.friendscube.com/">소모임 어플</a> 등에서 여러 동호회를
      찾아보세요.
    </p>
    <figure class="figure">
      <img src="../images/erections.png" class="figure-img img-fluid rounded" alt="남자들의 본성">
      <figcaption class="figure-caption text-center">남자는 다 이래요?</figcaption>
    </figure>
    <p>
      술 마시고 노는 단순 동호회 보다는 영어 모임, 스포츠 모임 등 뭔가 배울 수 있는 동호회를 찾아 보는 걸 잊지마세요!<br>
    </p>

    <h2>2. 랭귀지 익스체인지(언어 교환 모임)</h2>
    <a href="https://www.meetup.com/">밋업</a>에 가면 언어 교환 모임이 많아요.<br>
    홍대 모임, 강남역 모임, 구로디지털단지 모임 등등.<br>
    지역별로 하나씩은 다 있어요.<br>
    <figure class="figure">
      <img src="../images/meetup.png" class="figure-img img-fluid rounded" alt="수많은 언어 교환 모임들">
      <figcaption class="figure-caption text-center">밋업의 여러 언어 교환 모임들</figcaption>
    </figure>
    <br>
    이런 모임의 좋은 점은 외국어도 배우고 이성도 자연스럽게 만날 수 있다는 거에요.<br>
    술이나 먹고 영양가 없는 이야기를 하는 동호회들 보다는 훨씬 낫죠.<br>
    이런게 바로 님도 보고 뽕도 따는거 아닐까요?<br>

    <h2>3. 소개팅 어플</h2>
    <p>
      스마트폰이 나온지도 벌써 10년이 넘었어요.<br>
      코로나로 인해 밖을 못 나가니 소개팅 어플 사용이 폭발적으로 늘고 있다고 해요.<br>
      <br>
      <a href="https://matchgroup.app/">여기</a>서 요즘 많이 쓰는 소개팅 어플들을 살펴보세요.<br>
      아무나 가입할 수 있는 어플 보다는 뭔가 인증하고 가입할 수 있는 어플을 사용하기를 추천해요.
    </p>

    <h2>4. 피트니스</h2>
    <p>
      헬스하는 남자, 헬스하는 여자는 정말 매력적이죠.<br>
      <br>
      특히 출근 전 이른 새벽 시간에 운동하시는 분들은 자기 관리를 열심히 하는 사람은 안정적인 직장과 건강한 마음을 가지고 있음이 분명해요!
    </p>

    <h2>5. 조깅/자전거</h2>
    <p>
      주말에 혼자 조깅을 한다는 것?<br>
      데이트나 다른 약속이 없을 확률이 높죠~<br>
      <br>
      솔로인데 가벼운 운동으로 시간을 해소하는 분들은 정말 매력적이지 않나요?
    </p>

    <h2>6. 동네 오픈카톡 방</h2>
    <p>
      요즘엔 오픈카톡을 잘 쓰냐 아니냐에 따라 아재인지 아닌지 구별을 할 수 있어요.<br>
      오픈 채팅 방에도 지역 모임들이 많이 있으니 카톡을 열어서 검색해 보세요.<br>
      주로 사진 인증을 한 번 하고 나서 초대되는 방식이에요.
    </p>

    <h2>7. 교회와 성당 - 종교 활동</h2>
    <p>
      종교가 있으신 분들은 같은 종교를 선호하곤 하죠.<br>
      그럼 어떻게 해야 할까요?<br>
      <br>
      교회와 성당에 가세요. 이건 참 쉽죠?
    </p>

    <h2>8. 결정사를 알아보자</h2>
    <p>
      결혼정보업체도 그렇게 나쁜 선택이 아니라고 생각해요.<br>
      단 비용이 꽤 많이 들기 때문에 신중하게 선택하셔야 해요.<br>
      <br>
      <a href="https://matchgroup.app/apps/wedding_companies">여기</a>에 결혼정보업체들 정보가 있으니 각각의 홈페이지에 들어가서 꼼꼼하게 살펴보세요.
    </p>

    <h2>9. 길거리 헌팅</h2>
    <p>
      최근에는 코로나 때문에 길거리에서 아무한테나 말 걸면 안되요.<br>
      길거리 헌팅은 이제 아주 어려워 진 것 같아요.<br>
      그럼에도 길거리 헌팅으로 성공할 수 있다면 당신은 이미 레전드. 이 글을 읽을 필요가 없었네요.
    </p>
  </article>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  mounted() {
  }
});
</script>

<style lang="scss" scoped>
article {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #2c3e50;
  background-color: #fff;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    text-align: center;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .intro {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
    margin: 2rem auto;
    max-width: 600px;
    text-align: center;
  }

  h2 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin: 3rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #eee;
  }

  p {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 1.5rem;
    line-height: 1.8;

    a {
      color: #3498db;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border-color 0.2s ease;
      
      &:hover {
        border-color: #3498db;
      }
    }
  }

  figure {
    margin: 2rem 0;
    padding: 1rem;
    text-align: center;
    
    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }
    
    figcaption {
      margin-top: 1rem;
      font-size: 0.9rem;
      color: #666;
      font-style: italic;
    }
  }
}

@media (max-width: 768px) {
  article {
    padding: 1rem;

    h2 {
      font-size: 1.5rem;
      margin: 2rem 0 1rem;
    }

    p {
      font-size: 1rem;
    }
  }
}
</style>
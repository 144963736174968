<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top" style="background-color: #5E4843">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <i class="far fa-grin-hearts"></i>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-for="menu in menus" 
              :key="menu.title"
              class="nav-item"
              :class="{ dropdown: menu.type === 'dropdown' }">
            
            <!-- 일반 링크 메뉴 -->
            <a v-if="menu.type === 'link'"
               class="nav-link"
               :class="{ active: isMenuActive(menu) }"
               :href="menu.path">{{ menu.title }}</a>
            
            <!-- 드롭다운 메뉴 -->
            <template v-else>
              <a class="nav-link dropdown-toggle"
                 href="#"
                 :id="menu.id"
                 role="button"
                 data-bs-toggle="dropdown"
                 aria-expanded="false"
                 :class="{ active: isMenuActive(menu) }">
                {{ menu.title }}
              </a>
              <ul class="dropdown-menu" :aria-labelledby="menu.id">
                <li v-for="item in menu.items" :key="item.path">
                  <a class="dropdown-item" :href="item.path">{{ item.title }}</a>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      active: null,
      menus: [
        {
          type: 'link',
          title: '아티클',
          path: '/articles/everything_30s_dating',
          isActive: path => path === '/articles/everything_30s_dating'
        },
        {
          type: 'dropdown',
          id: 'navbarDropdown',
          title: '소개팅 어플',
          isActive: path => path && path.startsWith('/apps/'),
          items: [
            { title: '연도별 인기 소개팅 어플', path: '/apps/2024' },
            { title: '20대 소개팅 어플', path: '/apps/for20s' },
            { title: '30대 소개팅 어플', path: '/apps/for30s' },
            { title: '40대 소개팅 어플', path: '/apps/for40s' },
            { title: '직장 인증 어플', path: '/apps/companies' },
            { title: '돌싱 소개팅 어플', path: '/apps/divorcee' },
            { title: '프리미엄 소개팅 어플', path: '/apps/premium' },
            { title: '랜덤 채팅 어플', path: '/apps/random_chatting' }
          ]
        },
        {
          type: 'dropdown',
          id: 'navbarDropdown2',
          title: '온라인 커뮤니티',
          isActive: path => path && path.startsWith('/communities/'),
          items: [
            { title: '온라인 커뮤니티', path: '/communities/online' },
            { title: '직장인 커뮤니티', path: '/communities/company' },
            { title: '대학생 커뮤니티', path: '/communities/university' },
            { title: '여초 커뮤니티', path: '/communities/women' }
          ]
        },
        {
          type: 'link',
          title: '결혼 정보 회사',
          path: '/apps/wedding_companies',
          isActive: path => path && (path === '/apps/wedding_companies' || path === '/wedding_companies')
        },
        {
          type: 'link',
          title: '부동산 어플 순위',
          path: '/real_estate_apps',
          isActive: path => path === '/real_estate_apps'
        },
        {
          type: 'link',
          title: '사립초 지도',
          path: '/schools_map',
          isActive: path => path === '/schools_map'
        },
        {
          type: 'dropdown',
          id: 'navbarDropdown2',
          title: '유틸리티',
          isActive: path => path && path.startsWith('/character_counter'),
          items: [
            { title: '글자 수 세기', path: '/character_counter' },
            { title: '내 아이피 확인', path: '/my_ip' }
          ]
        }
      ]
    }
  },
  computed: {
    isMenuActive() {
      return (menu) => menu.isActive(this.active)
    }
  },
  mounted() {
    this.active = window.location.pathname;
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  $navbar-bg: #5E4843;
  
  background-color: $navbar-bg;
  
  &-nav {
    .nav-link {
      transition: color 0.2s ease;
      
      &:hover {
        color: rgba(255, 255, 255, 0.9);
      }
      
      &.active {
        font-weight: bold;
      }
    }
  }
  
  .dropdown-menu {
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}
</style>
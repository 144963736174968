<template>
  <h1>결정사의 모든 것(가격, 후기, 매칭 횟수 등)</h1>
  <p class="intro">
    안녕? 그동안 수 많은 결정사들에 상담 받아봤는데 궁금해하는 친구들이 많아서 한 번 정리를 해보기로 했어.<br>
    <br>
    다들 가격이 가장 궁금하지?<br>
    최소 가격은 300만원이라고 생각하면 돼 😱<br>
    그리고 상품 구성별로 가격이 점점 올라가는 구조야.<br>
    <br>
    참 나는 36살 여자고 외국계 회사에 다니고 있어. 연봉은 7,000만원 쯤이야.<br>
    아래에 하나 하나 자세하게 적어볼테니 궁금했던 정보들 잘 얻어가길 바래!<br>
    <br>
    리뷰를 보고 상담하기 전에 꼭 말해주고 싶은 게 있어.<br>
    결정사에 <b>일단 한 번 상담하게 되면 전화가 미친 듯이 와.</b><br>
    전화를 안 받으면 번호를 바꿔 가면서까지 지독하게 전화함.<br>
    나는 상담만 해야지~ 하고 생각했던 사람들도 꼭 이건 미리 알아두고 상담 전화하길 바래.
  </p>
  <address>
    혹시 제보해주고 싶은 내용 있으면 <a href="mailto:queens.soge@gmail.com">queens.soge@gmail.com</a>로 연락줘!
  </address>

  <section>
    <app-card :app="duo" rank="1"/>
    <div class="review">
      <p>
        상담하려고 전화를 하면 이름과 지역을 물어봐.<br>
        그리고 해당 지역 매니저가 10분 후 다시 전화해주겠다고 하지.<br>
        실제로 잠깐 기다리면 전화가 와.<br>
        <br>
        따르르르릉 ☎️<br>
        상담사는 친절했어. 여러가지 질문들을 해.
      </p>
      <ul>
        <li>
          혼자 거주하는지?
        </li>
        <li>몇 년생인지?</li>
        <li>결정사 이용 유무?</li>
        <li>종교, 키</li>
        <li>초혼, 재혼</li>
        <li>하는 일</li>
        <li>결혼을 생각하게 된 계기. 그 동안 왜 결혼 안했는지</li>
      </ul>

      <p>
        이런 답변들을 하고 나면 상품 종류를 알려줘.<br>
        아래 2개 말고도 더 많은 리스트가 있는 것 같은데 질문한 사람의 능력에 맞춰서 해주는 것 같은 느낌이 들었어.
      </p>

      <h2>상품과 가격</h2>
      <ul>
        <li>
          <h3>노블레스</h3>
        </li>
        <p>
          기본만남 5회. 1번 더 추가. 20대나 30초반의 사람들이 많이 가입.<br>
          상대는 공무원. 공기업.<br>
          <strong>330만원(VAT 포함)</strong>
        </p>

        <li>
          <h3>프라우드</h3>
        </li>
        <p>
          상대는 대기업. 전문직.<br>
          기본만남 5회. 3번 더 추가.<br>
          30중후반 남자들. 나에게는 이걸 추천해줬어.<br>
          <strong>451만원이고 VAT 포함</strong>
        </p>
      </ul>

      <p>
        아무래도 가격이 제일 궁금했지?<br>
        각오는 했지만 가입비가 역시나 후덜덜 하네...<br>
        <br>
        남자, 여자가 회비랑 프로그램은 동일하다고 했어.<br>
        가입 시 경력증명서와 가족관계증명서를 보내야 돼. 내가 말한게 사실인지 파악해보거든.
      </p>
      <p>
        신원인증이 완료되고 나면 드디어 프로필을 받게돼.<br>
        듀오 프로필에는 사진이 있어(결정사 마다 다름)<br>
        서로 만나겠다고 남녀가 동의하면 매니저가 만날 시간과 장소까지 정해줘.<br>
        만나기 몇 시간 전에 서로의 이름과 전화번호를 공개. ㅋㅋㅋ<br>
        <br>
        만남 이후에는 3,4일 후에 매니저가 전화해서 피드백을 받고 잘 안됐으면 보름에 한 번꼴로 다른 프로필을 보내주는 시스템이야.
      </p>
      <p>
        아래 내용도 매니저가 귀끔해줬는데 신빙성이 있는지는 나도 모르니 그냥 참고만 해.
      </p>
      <ul>
        <li>1년에 1000쌍이 결혼</li>
        <li>성혼율은 3명 중 한 명 결혼</li>
        <li>남자 회원은 30대가 전체의 2/3, 그다음 20대, 그 다음 40대</li>
        <li>여자는 30대 초반이 잘되는 시점. 30대가 젤 많다</li>
        <li>듀오 직원은 300명 이상. 수도권만 60명대</li>
        <li>남녀 성비가 반반이었는데 코로나 때문에 남성 전문직이 많이 들어와서 가입했다 함</li>
        <li>남자가 61 여자가 39 정도 성비라고</li>
      </ul>
    </div>
  </section>

  <section>
    <app-card :app="purples" rank="2"/>
    <div class="review">
      <p>
        퍼플스도 서울 본사에서 상담을 받아봤어.<br>
        무진장 친절하고 고급스러운 느낌의 아줌마(?)가 상담을 해줬어. ㅋ<br>
        <br>
        다른데랑 비슷하게 초혼 여부. 몇년생인지. 어디 사는지. 최종 학력. 무슨 일 하는지 등을 물어봐.
      </p>

      <h5>상품</h5>
      <p>
        여기는 횟수제랑 기간제로 상품이 나뉘어.<br>
        최소 300만원부터 100만원 단위로 올라가. 최대 1억까지. ㄷㄷㄷ<br>
        <br>
        횟수제는 295만원, 395만원, 495만원 짜리가 있다고 해. 부가세 미포함.<br>
        만남 횟수는 6번.<br>
        <br>
        기간제는 1년과 2년이 있는데<br>
        1년은 595만원, 695만원, 795만원<br>
        2년은 895만원, 995만원, 1095만원<br>
        <br>
        후덜덜하지?<br>
        나한테는 1년 기간제를 하라고 추천해줬어.<br>
        전문직을 희망하지 않으면 내 스펙으로는 500~600만원 짜리를 해보라고.<br>
        만약 전문직이 좋다면 800만원~2000만원 짜리를 가입하면 된다고.<br>
        기간제는 횟수 제한이 없고 결혼 할 때까지 계속 소개를 받는거야.
      </p>
      <h2>할인</h2>
      <p>
        가격이 넘 비싸니 할인이 안되나 궁금해서 물어봤지.<br>
        얼굴 이쁘거나 조건 좋으면 할인해줄수 있대. 부가세를 빼준다던지 하는 식으로.<br>
        그리고 이번 달에는 프로모션이 있어서 부가세를 빼준다고 하네.<br>
        내 생각에는 부가세 빼달라고 하면 그냥 다 빼주는 것 같아. ㅋㅋ<br>
        말 한마디 하면 되는데 안해서 호구 되지 않도록 해.
      </p>

      <h2>성혼비</h2>
      <p>
        여기는 특이하게 성혼비가 있다네. 결혼을 하게 되면 600만원 정도를 내야 한다고.<br>
        단 3번 만남 안에 결혼하면 안 받는대. 4번째 부터는 받는다고.<br>
        안 내는 사람들도 있을 것 같은데 결혼 한지는 어떻게 알아내냐고 물어보고 싶었지만 꾹 참았엌ㅋㅋㅋ
      </p>
    </div>
  </section>

  <section>
    <app-card :app="gayeon" rank="3"/>
    <div class="review">
      <p>
        가연은 기대와는 달리 좀 실망했어.<br>
        쌈마이 나는 상담이랄까.<br>
        <br>
        상담사마다 다르겠지만 내가 걸린 상담사는 무슨 책 읽듯이 마구 말을 쏟아내서...😢<br>
        <br>
        여기도 가족관계 증명서나 재직 증명서로 회사나 초혼 여부 등을 인증 해야해.<br>
        그 이후 원하는 남성상에 대해서 상담을 시작.<br>
        <br>
        1,2주 간격으로 두 세명의 프로필을 받아서 내가 선택 할 수 있어.<br>
        선택 하고 나면 상대에게도 내 프로필을 보내줌. 둘다 오케이 하면 만나는 시스템.
      </p>

      <h2>상품</h2>
      <p>
        가연의 상품은 <strong>일반</strong>하고 <strong>노블</strong>로 나뉘는데<br>
        일반은 고졸이나 전문대졸, 노블은 정규대학 4년제 졸업한 사람들이야.<br>
        이름을 왜 노블이라 붙였나 몰라 ㅋㅋ<br>
        <br>
        노블은 4종류가 있어. 횟수는 모두 5회로 동일.<br>
      </p>
      <ol>
        <li>루비 클래스</li>
        290만원. 안정적인 직군. 50대 대기업, 공기업. 공무원, 준공무원.
        <li>에메랄드 클래스</li>
        410만원. 전문직. 의사나 변호사겠지?
        <li>다이아몬드 클래스</li>
        620만원. 주변 환경까지 고려된 사람. 부모가 금수저라던지.
        <li>프라임</li>
        나는 쩌리라 그런지 설명도 안해주고 넘어감 샹 ㅋㅋㅋㅋ 막 재벌들 있고 그러려나?
      </ol>

      <h2>환불</h2>
      <p>
        낸 돈 중 20% 정도가 가입비고 나머지 80%는 진행비라고 생각하면 돼.<br>
        해지를 할 경우에 가입비 약 20%를 빼고 미진행된 횟수를 계산해서 환불이 가능하다고.<br>
        환불은 거의 하지 않는다고 하는데 글쎄. 내 생각에는 환불을 잘 안해주려고 할꺼 같은데...ㅋㅋ
      </p>

      <p>아래 내용도 매니저가 귀끔해줬는데 신빙성이 있는지는 나도 모르니 그냥 참고만 해.</p>
      <ul>
        <li>제휴된 회사가 많아서 고객 유치가 용이하다.</li>
        <li>한국 소비자 만족도에 클레임이 1%밖에 안된다.</li>
        <li>선호하는 이상형 파악을 확실하게 해서 시간낭비를 줄이려고 한다. <del>이건 당연한거 아니냐!</del></li>
        <li>성비는 남자가 48 여자가 52</li>
      </ul>
    </div>
  </section>

  <section>
    <app-card :app="baroyeon" rank="4"/>
    <div class="review">
      <p>
        대표번호로 전화를 하니 10분 안에 담당 지역 매니저가 전화준다고 했고 곧 팀장이라는 사람에게 전화가 왔어.<br>
        <br>
        이름이랑 나이, 무슨 일 하냐고 물어봄.<br>
        어디 사냐. 강남에 산다 그러니깐 사무실에 와서 얘기하자고 ㅎ<br>
        <br>
        계속 와서 얘기하자고 하는데 좀 짜증이 날 정도였어.<br>
        <br>
        대략적인 금액이 얼마냐 물어보니 얘기 안해주려고 함.<br>
        <br>
        듀오랑 가연이랑 비교하면서 걔네들 보다는 좀 싸다는 식으로 어필하더라구.<br>
        직접 만나야 정확한 가격을 정할 수 있다며 또 오라함 ㅋㅋ<br>
        <br>
        근데 웃긴게 내 연봉을 물어봐서 7천만원이라 대답하니깐 갑자기 목소리가 호의적으로 바뀌는거 있지 ㅋㅋ<br>
        <br>
        연봉이 높은 편이라 일반 상품보다는 노블레스 상품에 가입하는게 낫겠다고....<br>
        <br>
        정확한 금액은 말 안해주고 두리뭉실 상담해줬는데 200만원 부터 시작해서 100만원 단위로 올라가는 것 같아.<br>
        다른 곳들보다 싸거나 한 건 없는 듯. 결정사들 가격이 그냥 다 획일화 되어있고 똑같은 것 같아.<br>
        <br>
        여기 특이한 점은 뇌파 검사라는 걸 한대.<br>
        방문을 하면 무료로 뇌파 검사를 해준다고. 이걸 통해서 내가 어떤 사람을 좋아하는지를 알 수가 있다고 하는데 ㅋㅋㅋ<br>
        이런걸 진짜 하는 사람들이 있나? ㅋㅋㅋ 하다가 세뇌 당하는거 아냐? 나는 무서워서 이런건 도저히 못하겠네.<br>
        <br>
        와서 직접 상담을 하면 할인 해줄 수 있는 선에서는 다 할인을 해주겠다 했어. 그런데 상담이 불친절해서 방문은 안 할 예정.
      </p>
    </div>
  </section>

  <section>
    <app-card :app="nnoble" rank="5"/>
    <div class="review">
      <p>
        여기는 대표번호로 전화하니까 바로 지역 상담사로 넘겨주는게 아니고 대표번호의 상담사가 몇가지를 물어봤어.<br>
        본인 상담인지. 엔노블에 처음 전화하는건지<br>
        몇 년생인지, 어느 지역인지.<br>
        기본적인 것들을 물어보고 지역 매니저에게 토스.<br>
        <br>
        이런게 매니저 빨인건지 회사 빨인건지 잘 모르겠는데,<br>
        엔노블은 상담 경험이 가장 좋았어.<br>
        <br>
        무엇보다 매니저가 공감을 많이 해줘서 좋았어.<br>
        그리고 내 정보만 물어보는게 아니라 내 이상형은 뭔지, 상대방 직업은 어땠으면 좋겠는지 같은 질문들을 해주는게 좋았어.<br>
        <br>
        좀 더 배려받는 느낌이랄까.<br>
        <br>
        여기는 가입비가 가연, 듀오처럼 몇 회에 얼마 하는게 아니라 1년 회원제라고 해.<br>
        기본적으로 횟수는 5번 있고 그 이후로는 매니저 역량으로 소개를 시켜준다고...<br>
        근데 그게 그거 아닌가? 똑같은거 같은데... ㅋ<br>
        <br>
        <b>1년 회비는 무려 480만원...</b><br>
        <br>
        노블레스 결정사라고 회원들을 더 디테일 하게 케어해주고 관리해준다는데 그건 알 수 없는 노릇이니...<br>
        <br>
        서울에 있는 학교 21개에 제휴가 되어서 학교 동문들에게 광고도 꾸준하게 한다고 해.<br>
        학교 졸업생들은 20% 할인도 해주는 식으로해서 좋은 학벌 풀을 가져가려는 것 같아.<br>
        <br>
        성혼을 성사시키기 위해서 아주 디테일하게 만남 전에 코칭을 해준다고 하는데 코칭을 받아보지는 못했어.
      </p>
    </div>
  </section>

  <app-card :app="soohyun" rank="6"/>
  <app-card :app="dnoble" rank="7"/>
  <app-card :app="lemarry" rank="8"/>
  <app-card :app="iruyeon" rank="9"/>
  <app-card :app="jiin_love" rank="10"/>
  <app-card :app="jnoble" rank="11"/>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";
import AppCard from "./app_card.vue"

import baroyeon from '@/images/wedding/baroyeon.png'
import dnoble from '@/images/wedding/dnoble.jpg'
import duo from '@/images/wedding/duo.png'
import gayeon from '@/images/wedding/gayeon.png'
import jiin_love from '@/images/wedding/jiin.png'
import iruyeon from '@/images/wedding/iruyeon.png'
import jnoble from '@/images/wedding/jnoble.png'
import lemarry from '@/images/wedding/lemarry.png'
import nnoble from '@/images/wedding/nnoble.png'
import purples from '@/images/wedding/purples.png'
import suhyun from '@/images/wedding/suhyun.png'

export default {
  props: {},
  components: {GlobalNavHeader, AppCard},
  data() {
    return {
      duo: {
        title: '듀오',
        hashTags: ['국내 최대 규모', '1세대', '1995년 시작', '듀오정보', '일반 대중'],
        star: 3.9,
        company: '듀오',
        logo: duo,
        serviceUrl: 'https://www.duo.co.kr/',
        companyUrl: 'https://www.duo.co.kr/html/company/company_manage.asp',
      },
      purples: {
        title: '퍼플스',
        hashTags: ['노블레스 결정사', '상류층', '2001년 설립', '대한민국 최초 상류층 결정사', '유학파 결정사로 시작', '퍼플스 파티'],
        star: 4.0,
        company: '퍼플스',
        logo: purples,
        serviceUrl: 'http://www.purples.co.kr/',
        companyUrl: 'http://www.purples.co.kr/',
      },
      gayeon: {
        title: '가연',
        hashTags: ['일반 대중', '국내 2위'],
        star: 2.9,
        company: '가연결혼정보',
        logo: gayeon,
        serviceUrl: 'https://m.gayeon.com/index.asp',
        companyUrl: 'https://m.gayeon.com/index.asp',
        downloadUrl: 'https://play.google.com/store/apps/details?id=com.gayeon.service.once',
      },
      baroyeon: {
        title: '바로연',
        hashTags: ['일반 대중', '사외 이사 이무송'],
        star: 3.5,
        company: '바로연',
        logo: baroyeon,
        serviceUrl: 'https://m.baroyeon.co.kr/',
        companyUrl: 'https://m.baroyeon.co.kr/',
      },
      nnoble: {
        title: '엔노블',
        hashTags: ['노블레스 결정사', '2007년 설립', '작원 40명 규모', '압구정동 7층 사옥', '성혼비 받음'],
        star: 3.9,
        company: '엔노블',
        logo: nnoble,
        serviceUrl: 'https://www.nnoble.co.kr/',
        companyUrl: 'https://www.nnoble.co.kr/',
      },
      suhyun: {
        title: '노블레스 수현',
        hashTags: ['노블레스 결정사', '논현동'],
        star: 3.6,
        company: '수현',
        logo: suhyun,
        serviceUrl: 'https://isoohyun.co.kr/',
        companyUrl: 'https://isoohyun.co.kr/',
      },
      jnoble: {
        title: '제이노블',
        hashTags: ['노블레스 결정사', '신사동'],
        star: 2.5,
        company: '제이노블결혼정보',
        logo: jnoble,
        serviceUrl: 'http://www.jnoble.co.kr/',
        companyUrl: 'http://www.jnoble.co.kr/',
      },
      lemarry: {
        title: '르매리',
        hashTags: ['2007년 시작', '알앤디 클럽', '국내 유일 재혼 전문'],
        star: 3.5,
        company: '르매리',
        logo: lemarry,
        serviceUrl: 'http://www.lemarry.co.kr/',
        companyUrl: 'http://www.lemarry.co.kr/',
      },
      dnoble: {
        title: '디노블',
        hashTags: ['2004년 시작', '유학파', '전문직', '정재계 명문가', '노블레스 결정사', '두리조아', '기독교 전문 서비스 제공'],
        star: 3.2,
        company: '디노블',
        logo: dnoble,
        serviceUrl: 'http://www.dnoble.co.kr/',
        companyUrl: 'http://www.dnoble.co.kr/',
      },
      soohyun: {
        title: '노블레스 수현',
        hashTags: ['전문직 성혼 매칭', '신사역 2번 출구', '노블레스 결정사'],
        star: 3.8,
        company: '수현',
        logo: suhyun,
        serviceUrl: 'https://www.isoohyun.co.kr/',
        companyUrl: 'https://www.isoohyun.co.kr/',
      },
      iruyeon: {
        title: '이루연',
        hashTags: ['이루어지는 인연의 명당', '초혼 전문', '종교 전문'],
        star: 3.3,
        company: '이루연',
        logo: iruyeon,
        serviceUrl: 'http://iruyeon.com/default/',
        companyUrl: 'http://iruyeon.com/default/',
      },
      jiin_love: {
        title: '모두의 지인',
        hashTags: ['AI 결정사', '프리미엄 결정사', '성지인', '강남대로', '논현역'],
        star: 3.7,
        company: '(주)테키',
        logo: jiin_love,
        serviceUrl: 'https://jiin.love',
        companyUrl: 'https://jiin.love',
      },
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@use '../stylesheets/common' as common;

#wedding_companies {
  font-size: 16px;

  .intro {
    padding: 15px 15px 0 15px;
  }

  address {
    padding: 0 15px;
    margin-bottom: 50px;
  }

  .review {
    margin-top: 0;
    padding: 0 30px 30px 30px;

    p {
      font-size: 15px;
    }

    ol, ul {
      font-size: 14px;

      li {
        margin-top: 10px;
      }
    }
  }
}
</style>
<template>
  <div>
    <h1>서울 사립초 지도</h1>
    <l-map
      style="height: 600px; width: 100%;"
      :zoom="zoom"
      :min-zoom="10"
      :center="[37.5509603, 126.979409]"
    >
      <l-tile-layer :url="tileUrl"/>
      <l-geo-json
        v-if="geojsonData"
        :geojson="geojsonData"
        :options-style="styleFeature"
        @click="onFeatureClick"
      />

      <l-marker
        v-for="(school, index) in schools"
        :key="index"
        :lat-lng="[school.lat, school.lng]"
        :name="school.name"
        @click="onMarkerClick"
      >
        <l-tooltip v-if="school['입학 접수일']">{{ school['입학 접수일'] }}</l-tooltip>
        <l-icon class-name="leaflet-div-icon" :icon-size="[school.name.length > 4 ? 55 : 35, 20]" :icon-url="iconUrl">
          {{ school.name }}
        </l-icon>
      </l-marker>
    </l-map>

    <h2>서울 시내 사립초등학교 입학 일정</h2>
    <table>
      <thead>
      <tr>
        <th>학교 이름</th>
        <th>입학 접수일</th>
        <th>입학 설명회</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(school, index) in schools" :key="index">
        <td><a :href="school.홈페이지" target="_blank">{{ school.name }}</a></td>
        <td>{{ school['입학 접수일'] || 'N/A' }}</td>
        <td>{{ school['입학 설명회'] || 'N/A' }}</td>
      </tr>
      </tbody>
    </table>

    <h2>함께 참여해주세요</h2>
    <p>
      이 프로젝트는 <a
      href="https://docs.google.com/spreadsheets/d/1GnJvIj7mPMHrBGS7q74GWSfSuFKZsFygB2mPQT5-ljw/edit?gid=0#gid=0"
      target="_blank">서울시 사립초 목록</a>을 기반으로 만들어졌습니다.<br>
      함께 데이터를 채워주세요.
    </p>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import L from 'leaflet'
import {
  LIcon,
  LGeoJson,
  LMap,
  LMarker,
  LTileLayer,
  LTooltip,
} from '@vue-leaflet/vue-leaflet';
import axios from 'axios';
import {feature} from 'topojson-client';


import { defineProps } from 'vue'

const props = defineProps({
  schools: {
    type: Array,
    required: true,
  }
})

const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const geojsonData = ref(null);
const zoom = ref(11);

const iconUrl = computed(() => '/school.png');

onMounted(() => {
  axios
    .get('/seoul_municipalities_topo_simple.json')
    .then((response) => {
      geojsonData.value = feature(
        response.data,
        response.data.objects.seoul_municipalities_geo
      );
    })
    .catch((error) => {
      console.error('Error loading TopoJSON data:', error);
    })
    .finally(() => {
      console.log('Data loaded');
    });
});

const styleFeature = (feature) => {
  return {
    color: '#ffff00',
    weight: 1,
    fillColor: '#2abf4a',
    fillOpacity: 0.3,
  };
};

const onMarkerClick = (event) => {
  const latLng = event.latlng;
  const markerOptions = event.target.options;
  console.log(`Marker clicked at coordinates: ${latLng.lat}, ${latLng.lng}`);
  console.log('Marker options:', markerOptions);
  console.log('Marker iconName:', event.target.options.iconName);
};

const onFeatureClick = (event) => {
  console.log(event);
  const properties = event.layer.feature.properties;
  console.log(`${properties.name}을(를) 클릭하셨습니다.`);
};
</script>

<style lang="scss" scoped>
@use '../stylesheets/common' as common;

$primary-color: #2c3e50;
$container-width: 600px;

#schools_map {
  .leaflet-div-icon {
    background: steelblue;
    color: rgba(0, 0, 255, 0.5);
    border-radius: 50%;
    font-weight: bold;
    font-size: large;
    text-align: center;
    line-height: 21px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ddd;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
      text-align: left;
    }
  }
}
</style>
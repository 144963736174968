<template>
  <div class="real-estate-apps">
    <header class="page-header">
      <h1 class="title">2024년 인기 부동산 어플 순위 TOP 10</h1>
      <p class="subtitle">실거래가 조회부터 매물 검색까지 한번에</p>
    </header>

    <div class="meta-description" aria-hidden="true">
      <p>호갱노노, 직방, 네이버부동산 등 2024년 최신 부동산 앱 리뷰와 순위를 제공합니다. 
         실거래가 조회, 매물 검색, 시세 확인 등 각 앱의 특징과 장단점을 상세히 분석했습니다.</p>
    </div>

    <div class="apps-grid" role="list">
      <section v-for="(app, index) in sortedApps" 
               :key="app.title" 
               class="app-section"
               :class="{'top-rank': index < 3}"
               role="listitem">
        <app-card :app="app" :rank="index + 1"/>
        <div class="review">
          <h2 class="app-title">{{ app.title }}</h2>
          <p v-html="formatReview(app.review)"></p>
          
          <div v-if="app.features" class="features">
            <h3>{{ app.title }} 주요 기능</h3>
            <ul>
              <li v-for="feature in app.features" 
                  :key="feature">{{ feature }}</li>
            </ul>
          </div>
        </div>
      </section>
    </div>

    <footer class="article-footer">
      <p class="update-info">최근 업데이트: 2024년 3월</p>
    </footer>
  </div>
</template>

<script>
import AppCard from "./app_card.vue"
import hogangnono from '@/images/real_estate_apps/호갱노노.png'
import nemo from '@/images/real_estate_apps/네모.png'
import naver_real_estate from '@/images/real_estate_apps/네이버부동산.png'
import asil from '@/images/real_estate_apps/아실.png'
import dabang from '@/images/real_estate_apps/다방.png'
import disco from '@/images/real_estate_apps/디스코.png'
import buplanet from '@/images/real_estate_apps/부동산플래닛.png'
import spacecloud from '@/images/real_estate_apps/스페이스클라우드.png'
import zigbang from '@/images/real_estate_apps/직방.png'
import ziptoss from '@/images/real_estate_apps/집토스.png'
import peterpan from '@/images/real_estate_apps/피터팬.png'
import valuemap from '@/images/real_estate_apps/valuemap.png'

export default {
  name: 'RealEstateApps',
  components: { AppCard },
  
  data() {
    return {
      apps: {
        호갱노노: {
          title: '호갱노노',
          hashTags: ['아파트 실거래가', '오피스텔'],
          star: 4.4,
          company: '호갱노노',
          logo: hogangnono,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.hogangnono.hogangnono&hl=ko&gl=US',
          companyUrl: 'https://career.zigbang.com/info',
          review: '호갱노노는 부동산 어플의 최고봉이라 할 수 있지.\n입이 떡 벌어질 정도의 기능과 편리한 UI.\n채광까지 시뮬레이션 해주는 기능을 보고는 정말 입을 다물지 못했어.\n부동산 어플 중 단연 젤 잘 만든 어플 같아.\n아파트나 오피스텔 가격 추이를 보고 싶으면 호갱노오 하나만 있으면 돼.\n직방에 인수된 후로는 발전이 좀 더뎌진 것 같지만 말야.',
          features: ['실시간 시세 확인', '채광 시뮬레이션', '실거래가 분석', '매물 알림']
        },
        네이버부동산: {
          title: '네이버 부동산',
          hashTags: ['대중'],
          star: 4.0,
          company: '네이버',
          logo: naver_real_estate,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.nhn.land.android&hl=ko&gl=US',
          companyUrl: 'https://navercorp.com',
          review: '아무리 새로운 어플들이 나와도 구관이 명관인 법.\n네이버 부동산은 절대 죽지 않아. 네이버 Never die.',
          features: ['매물 검색', '시세 조회', '부동산 뉴스', '실거래가 조회']
        },
        직방: {
          title: '직방',
          hashTags: ['안성우', '부동산 중개 어플 1인자'],
          star: 3.8,
          company: '직방',
          logo: zigbang,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.chbreeze.jikbang4a&hl=ko&gl=US',
          companyUrl: 'https://career.zigbang.com/info',
          review: '모바일 어플들이 태동하던 시절, 빠른 타이밍에 진입해서 시장을 싹 쓸어버렸지.\n부동산도 중개앱으로 나오는구나 싶었던 사람들이 이제는 자연스럽게 집 구할 때 찾게하는 어플.\n아파트보다는 빌라 같은 주택에 아무래도 많이 쓰이지.',
          features: ['매물 검색', '허위매물 신고', '지도 기반 검색', '관심 매물 알림']
        },
        아실: {
          title: '아실',
          hashTags: ['아파트 실거래가', '부동산 심리지수', '거래량', '갭투자', '매수심리'],
          star: 4.0,
          company: '아실',
          logo: asil,
          serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.koreachart.city&hl=ko&gl=US',
          companyUrl: 'https://asil.kr/asil/index.jsp',
          review: '아파트 실거래가 보기 좋아.\n특히 주택 가격 심리 지수를 무려 2008년부터 그래프로 보여주는데 나는 이게 너무 좋더라.\n매수 심리는 거의 가격에 비례하거든.\n이것만 트랙킹 해도 분위기를 어느 정도 알 수 있어.',
          features: ['실거래가 분석', '매매가 추이', '심리지수 분석', '거래량 분석']
        },
        피터팬: {
          title: '피터팬의 좋은방 구하기',
          hashTags: ['직거래', '두꺼비세상', '헌집줄게 새집다오', '원룸', '투룸'],
          star: 3.4,
          company: '피터팬',
          logo: peterpan,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.appz.dukkuba',
          companyUrl: 'http://www.duse.co.kr/views/home.html',
          review: '부동산 직거래 어플이야. 세입자가 이사갈 때 자기 방 올리라고 만든 곳.\n복비를 아끼려는 사람들은 이 서비스를 꼭 써봐.\n요즘엔 당근마켓에도 부동산 직거래가 서비스가 나왔더라구.(아직 서울 한정)',
          features: ['직거래 매물', '복비 절약', '실시간 알림', '채팅']
        },
        다방: {
          title: '다방',
          hashTags: ['직방을 뒤따르는 중개앱 2인자'],
          star: 4.1,
          company: 'station3',
          logo: dabang,
          serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.station3.dabang&hl=ko&gl=US',
          companyUrl: 'https://www.station3.co.kr/',
          review: '직방의 뒤를 잇고 있는 부동산 중개앱이지만 어플 자체는 직방보다 잘 만든 것 같아.\nUI도 직방보다 낫고 기능도 좋고.\n근데 영 직방을 이기지 못한단 말이지.\n기술적인 부분 말고 허위매물 관리에 좀 더 신경쓰면 어떨까?',
          features: ['매물 검색', '방 찾기 필터', '지도 검색', '관심 매물 저장']
        },
        네모: {
          title: '네모',
          hashTags: ['사무실', '상업용 부동산', '점포'],
          star: 3.9,
          company: '슈가힐',
          logo: nemo,
          serviceUrl: 'https://play.google.com/store/apps/details?id=kr.co.sugarhill.nemoapp&hl=ko&gl=US',
          companyUrl: 'https://www.sugarhill.co.kr/',
          review: '부동산엔 주택이나 아파트만 있는게 아니야.\n자영업자들이나 소규모 회사 대표들은 사무실 구하는게 참 일이거든.\n네모는 이런 사람들을 위한 서비스야.\n상가사 사무실 필요하다면 다른 어플들보다 네모를 찾아봐.',
          features: ['상가/사무실 매물', '권리금 정보', '상권 분석', '임대료 시세']
        },
        집토스: {
          title: '집토스',
          hashTags: ['복비 무료'],
          star: 4.1,
          company: '집토스',
          logo: ziptoss,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.ziptoss.v2&hl=ko&gl=US',
          companyUrl: 'https://ziptoss.com/',
          review: '세입자에게는 복비를 안받는다.\n집주인이게만 복비를 받는다.\n기득권 부동산들로부터 공격을 많이 받고 있지만 나는 응원해주고 싶어!\n나도 세입자라구...',
          features: ['무료 중개', '실시간 매물', '집주인 직거래', '계약서 작성']
        },
        밸류맵: {
          title: '밸류맵 - 건물 토지 실거래가',
          hashTags: ['밸류맵', '건물', '토지', '실거래가', '건물 매각', '건물 임장', '건물주'],
          star: 3.9,
          company: '밸류업시스템즈',
          logo: valuemap,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.valueupsystems.valuemap&hl=ko&gl=US',
          companyUrl: 'https://www.valueupmap.com/',
          review: '건물 가격을 보여주는 어플이야.\n전국의 모든 건물과 토지를 보여주기 때문에 건물 가격을 보고 싶다면 이 어플을 써봐.\n아파트는 호갱노노를 보면 되지만 다가구 주택이나 다세대 주택은 밸류맵만한 게 없어.\n건물 실거래가는 물론 현재 나와있는 매물까지도 볼 수 ��다구.',
          features: ['건물가격 조회', '토지가격 조회', '실거래가 분석', '매물 정보']
        },
        디스코: {
          title: '디스코',
          hashTags: ['주택 매매', '실거래가', '주택 거래'],
          star: 4.0,
          company: '디스코',
          logo: disco,
          serviceUrl: 'https://play.google.com/store/apps/details?id=me.aftergrowth.disco&hl=ko&gl=US',
          companyUrl: 'https://disco.re',
          review: '밸류맵과 비슷한 어플이야. 건물과 토지 실거래가를 보여주는 서비스.\n등기부등본을 쉽게 떼어볼 수 있는 서비스가 참 좋아.',
          features: ['실거래가 조회', '등기부등본 발급', '건물 정보', '시세 분석']
        },
        스페이스클라우드: {
          title: '스페이스클라우드',
          hashTags: ['파티룸', '공간 대여'],
          star: 4.2,
          company: '스페이스클라우드',
          logo: spacecloud,
          serviceUrl: 'https://play.google.com/store/apps/details?id=com.hustay.swing.p3cfded673ced4983b0db9399638730cb&hl=ko&gl=US',
          companyUrl: 'https://www.spacecloud.kr/',
          review: '공간대여라는 말 들어봤어?\n파티룸 같은 공간을 1시간 단위로 빌려서 놀 수 있는 곳이야.\n코로나 이후 상가와 사무실들이 타격을 많이 입었어.\n그런데 의외로 잘하고 있는 곳들이 있거든. 바로 파티룸 공간 사업.\n거리두기로 사람들이 저녁 늦게까지 놀 수가 없으니 이런 공간들을 빌려서 놀기 시작한거야.\n스페이스클라우드는 그런 파티룸들을 중개해주는 서비스지.',
          features: ['공간 예약', '시간단위 대여', '공간 검색', '리뷰 시스템']
        }
      }
    }
  },

  computed: {
    sortedApps() {
      return Object.values(this.apps).sort((a, b) => b.star - a.star)
    }
  },

  methods: {
    formatReview(text) {
      return text.replace(/\n/g, '<br>')
    }
  },

  metaInfo() {
    return {
      title: '2024년 인기 부동산 어플 순위 TOP 10 - 호갱노노, 직방, 네이버부동산 비교',
      meta: [
        { 
          name: 'description', 
          content: '2024년 최신 부동산 앱 순위와 리뷰. 호갱노노, 직방, 네이버부동산 등 인기 부동산 앱의 특징과 장단점을 상세 분석. 실거래가 조회, 매물 검색, 시세 확인 기능 비교.'
        },
        { 
          property: 'og:title',
          content: '2024년 인기 부동산 어플 순위 TOP 10'
        },
        {
          property: 'og:description',
          content: '호갱노노, 직방, 네이버부동산 등 2024년 최신 부동산 앱 리뷰와 순위'
        },
        {
          name: 'keywords',
          content: '부동산 어플,호갱노노,직방,네이버부동산,부동산 앱 순위,실거래가 조회,매물 검색,2024'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.real-estate-apps {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  
  .page-header {
    text-align: center;
    margin-bottom: 3rem;
    
    .title {
      font-size: 2.5rem;
      font-weight: bold;
      color: #2c3e50;
      margin-bottom: 0.5rem;
    }
    
    .subtitle {
      font-size: 1.2rem;
      color: #666;
    }
  }

  .apps-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }

  .app-section {
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    &.top-rank {
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234CAF50"><path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"/></svg>') no-repeat center;
        background-size: contain;
        z-index: 2;
      }
    }
  }

  .review {
    margin-top: 1.5rem;
    padding: 0 1rem;
    
    p {
      font-size: 1rem;
      line-height: 1.6;
      color: #444;
    }
    
    .features {
      margin-top: 1rem;
      
      h3 {
        font-size: 1.1rem;
        color: #2c3e50;
        margin-bottom: 0.5rem;
      }
      
      ul {
        list-style: disc;
        padding-left: 1.5rem;
        
        li {
          margin: 0.5rem 0;
          color: #666;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .real-estate-apps .apps-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .real-estate-apps {
    padding: 1rem;
    
    .page-header .title {
      font-size: 1.75rem;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .real-estate-apps {
    .page-header .title {
      color: #000000;
    }

    .app-section {
      background: rgba(255, 255, 255, 0.9);
      
      &.top-rank::before {
        filter: brightness(0.8);
      }
    }
  }
}

.meta-description {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.app-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.article-footer {
  margin-top: 3rem;
  text-align: center;
  
  .update-info {
    color: #666;
    font-size: 0.9rem;
  }
}
</style>
<template>
  <div class="generation-content">
    <header class="page-header">
      <h1 class="page-title" v-text="getTitle()"></h1>
      <div class="header-decoration"></div>
    </header>

    <div class="apps-grid">
      <section 
        v-for="(app, rank) in apps" 
        :key="rank"
        class="app-section"
        :class="{'top-rank': rank < 3}"
      >
        <app-card :app="app" :rank="rank + 1" />
      </section>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import AppCard from './app_card.vue';

  export default defineComponent({
    name: 'ForGeneration',
    props: {
      apps: {
        type: Array,
        required: true
      },
      generation: {
        type: String,
        required: false,
      },
      headingTitle: {
        type: String,
        default: ''
      }
    },
    components: {AppCard},
    data() {
      return {
      }
    },
    methods: {
      getTitle() {
        if (this.headingTitle) {
          return this.headingTitle
        } else {
          return this.generation + "대 소개팅 어플 순위"
        }
      },
    },
    mounted() {
    }
  });
</script>

<style lang="scss" scoped>
.generation-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.page-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  padding: 2rem 0;
}

.page-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #000000;
  margin: 0;
  line-height: 1.3;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(to right, #4CAF50, #45a049);
    border-radius: 2px;
  }
}

.header-decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(76, 175, 80, 0.1) 0%, rgba(76, 175, 80, 0) 70%);
  border-radius: 50%;
  z-index: -1;
}

.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
  max-width: 100%;
}

.app-section {
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
  
  &.top-rank {
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      width: 40px;
      height: 40px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234CAF50"><path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"/></svg>') no-repeat center;
      background-size: contain;
      z-index: 2;
    }
  }
}

// 반응형 디자인
@media (min-width: 768px) {
  .apps-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .apps-grid {
    grid-template-columns: 1fr;
  }

  .page-title {
    font-size: 1.75rem;
    padding: 0 1rem;
  }
}

// 다크 모드 지원
@media (prefers-color-scheme: dark) {
  .page-title {
    color: #000000;
  }

  .header-decoration {
    background: radial-gradient(circle, rgba(76, 175, 80, 0.05) 0%, rgba(76, 175, 80, 0) 70%);
  }

  .app-section {
    &.top-rank::before {
      filter: brightness(0.8);
    }
  }
}

// 애니메이션
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.app-section {
  animation: fadeIn 0.5s ease forwards;
  
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: #{$i * 0.1}s;
    }
  }
}

// 프린트 스타일
@media print {
  .generation-content {
    padding: 0;
  }

  .app-section {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>